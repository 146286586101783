import { Navigate, Route, Routes } from "react-router-dom";
import ContactEdit from "./Edit";
import ContactList from "./List";
import ContactNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<ContactList />} />
      <Route path="new" element={<ContactNew />} />
      <Route path=":id/edit" element={<ContactEdit />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
