import { Image, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { colors } from "../lib/constants";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="h-[300px] flex flex-col items-center justify-center mt-16">
      <div className="w-32 mb-4">
        <Image src={`/logo.jpeg`} preview={false} className="pt-5" />
      </div>
      <div className="mb-4">
        <a
          href="https://www.facebook.com"
          className="mr-2"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebook size={20} color="#1877F2" />
        </a>
        <a
          href="https://www.linkedin.com"
          className="mr-2"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin size={20} color="#0A66C2" />
        </a>
        <a
          href="https://www.instagram.com"
          className="mr-2"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram size={20} color="#fa7e1e" />
        </a>
      </div>
      <div className="flex pb-1">
        <Typography.Text className="font-bold flex items-center text-xs">
          <AiFillMail className="mr-2" />
          <span>info@ezmsip.com</span>
        </Typography.Text>
        <span className="font-bold mx-2" style={{ color: colors.blue }}>
          |
        </span>
        <Typography.Text className="font-bold flex items-center text-xs">
          <span>
            {window.location.hostname === "ezmsip.com"
              ? "+55 11 995583381"
              : window.location.hostname === "us.ezmsip.com"
              ? "+1 7867221911"
              : "+91 6364549005"}
          </span>
          <AiFillPhone className="ml-1" />
        </Typography.Text>
      </div>
      <Typography.Text className="font-bold text-xs">
        &copy; {t("footer")}
      </Typography.Text>
    </div>
  );
}
