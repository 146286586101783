import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { SiBrandfolder } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IEntity } from "../../../lib/model";
import { getBase64 } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  photo_main: UploadFile[];
  entity: IEntity | null;
};

export default function BrandNew() {
  useTitle("New Brand");

  const [info, setInfo] = useState<TInfo>({
    photo_main: [],
    entity: null,
  });
  const [entities, setEntities] = useState<IEntity[]>([]);

  const navigate = useNavigate();
  const {
    dispatch,
    state: { user },
  } = useCTX();
  const { onPreview } = useImagePreview();
  const [request, loading] = useRequest();
  const [listRequest, listLoading] = useRequest(true);

  const onCreate = (values: any) => {
    const _info = new FormData();
    _info.append("token", localStorage.getItem("token")!);
    _info.append("brand_name", values.brand_name);
    _info.append("brand_nick", values.brand_nick);
    _info.append("photo_main", info.photo_main[0].originFileObj!);

    if (info.entity) {
      _info.append("entityId", `${info.entity.id}`);
    }

    request(
      "post",
      "campaigns/api/brands/webaddupdate/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "BRAND",
            description: "Brand created successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "entities/api/list/",
      (res) => {
        setEntities(res.data.entity_list);
      },
      {
        email: user?.email,
        list_type: "private",
        pageRequested: 1,
      }
    );
  }, [user?.email, listRequest]);

  if (listLoading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <SiBrandfolder className="mr-2" /> Brand
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onCreate}>
          <Form.Item
            label="Photo"
            name="photo_main"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.photo_main.length === 0) {
                    return Promise.reject("Please add a photo!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={info.photo_main}
              onPreview={onPreview}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
              onChange={({ fileList }) =>
                setInfo({ ...info, photo_main: fileList })
              }
            >
              {info.photo_main.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Name"
            name="brand_name"
            rules={[{ required: true, message: "Please input brand name!" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item label="Nickname" name="brand_nick">
            <Input />
          </Form.Item>
          <Form.Item label="Entity" name="entity">
            <Dropdown
              trigger={["click"]}
              menu={{
                items: entities.map((e) => ({
                  key: e.name,
                  label: e.name,
                  icon: (
                    <Avatar
                      src={e?.photo ? e.photo : "/images/covers/empty.png"}
                      className="w-10 h-10"
                    />
                  ),
                  onClick: () => setInfo({ ...info, entity: e }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.entity ? (
                  <div className="flex items-center">
                    <Avatar
                      src={
                        info.entity?.photo
                          ? info.entity.photo
                          : "/images/covers/empty.png"
                      }
                      className="w-8 h-8 mr-2"
                    />
                    <span>{info.entity.name}</span>
                  </div>
                ) : (
                  "Select the Entity"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
