import {
  Button,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { CiVideoOn } from "react-icons/ci";
import { FaBuysellads } from "react-icons/fa6";
import { IoAdd } from "react-icons/io5";
import { MdAudiotrack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import {
  ICampaign,
  IProduct,
  MAdChannel,
  MAdInteract,
} from "../../../lib/model";
import { getBase64, getDomain } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../lib/firebase";
import { nanoid } from "nanoid";

type TInfo = {
  campaign: ICampaign | null;
  product: IProduct | null;
  channel: string;
  interact: string;
  banner: UploadFile[];
  video: UploadFile[];
  audio: UploadFile[];
};

export default function AdNew() {
  useTitle("New Ad");

  const [info, setInfo] = useState<TInfo>({
    campaign: null,
    product: null,
    channel: "1",
    interact: "99",
    banner: [],
    video: [],
    audio: [],
  });
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);

  const navigate = useNavigate();
  const { dispatch } = useCTX();
  const { onPreview } = useImagePreview();
  const [request, loading] = useRequest();
  const [creating, setCreating] = useState(false);
  const [campaignListRequest, campaignListLoading] = useRequest(true);
  const [productListRequest, productListLoading] = useRequest(true);

  const onCreate = async (values: any) => {
    setCreating(true);

    const _info = new FormData();
    _info.append("token", localStorage.getItem("token")!);
    _info.append("ad_name", values.ad_name);
    _info.append("channel", info.channel);
    _info.append("interact", info.interact);
    _info.append("campaignId", `${info.campaign?.id}`);
    _info.append("productId", `${info.product?.id}`);

    if (values.text) {
      _info.append("text", values.text);
    }

    if (info.banner.length > 0) {
      _info.append("banner", info.banner[0].originFileObj!);
    }

    if (info.video.length > 0) {
      const videoURL = await new Promise((res, _) => {
        const file = info.video[0];
        const fref = ref(
          storage,
          `ezmsip/${getDomain()}/ads/videos/${nanoid(5)}.${
            file.type?.split("/")[1]
          }`
        );

        const uploader = uploadBytesResumable(fref, file.originFileObj!);

        uploader.on("state_changed", null, null, () => {
          getDownloadURL(uploader.snapshot.ref).then((url) => {
            console.log("here inside");
            console.log(url);
            res(url);
          });
        });
      });

      _info.append("video", `${videoURL}`);
    }

    if (info.audio.length > 0) {
      const audioURL = await new Promise((res, _) => {
        const file = info.audio[0];
        const fref = ref(
          storage,
          `ezmsip/${getDomain()}/ads/audios/${nanoid(5)}.${
            file.type?.split("/")[1]
          }`
        );

        const uploader = uploadBytesResumable(fref, file.originFileObj!);

        uploader.on("state_changed", null, null, () => {
          getDownloadURL(uploader.snapshot.ref).then((url) => {
            res(url);
          });
        });
      });

      _info.append("audio", `${audioURL}`);
    }

    request(
      "post",
      "campaigns/api/ads/webadd/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "AD",
            description: "Ad created successfully!",
          })
        );
        setCreating(false);
      },
      _info
    );
  };

  useEffect(() => {
    campaignListRequest(
      "post",
      "campaigns/api/campaigns/websearch/",
      (res) => {
        setCampaigns(res.data.campaigns);
      },
      {
        token: localStorage.getItem("token"),
        pageRequested: 1,
      }
    );
  }, [campaignListRequest]);

  useEffect(() => {
    productListRequest(
      "post",
      "campaigns/api/products/websearch/",
      (res) => {
        setProducts(res.data.products);
      },
      {
        token: localStorage.getItem("token"),
        pageRequested: 1,
      }
    );
  }, [productListRequest]);

  if (campaignListLoading || productListLoading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <FaBuysellads className="mr-2" /> Ad
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onCreate}>
          <Form.Item
            label="Name"
            name="ad_name"
            rules={[{ required: true, message: "Please input ad name!" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Campaign"
            name="campaign"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.campaign)
                    return Promise.reject("Please input campaign!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: campaigns.map((c) => ({
                  key: c.campaign_name,
                  label: c.campaign_name,
                  onClick: () => setInfo({ ...info, campaign: c }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.campaign ? (
                  <div className="flex items-center">
                    <span>{info.campaign.campaign_name}</span>
                  </div>
                ) : (
                  "Select the Campaign"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Product"
            name="product"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.product)
                    return Promise.reject("Please input product!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: products.map((p) => ({
                  key: p.product_name,
                  label: p.product_name,
                  onClick: () => setInfo({ ...info, product: p }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.product ? (
                  <div className="flex items-center">
                    <span>{info.product.product_name}</span>
                  </div>
                ) : (
                  "Select the Product"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Channel"
            name="channel"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.channel)
                    return Promise.reject("Please input ad channel!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MAdChannel.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      channel: MAdChannel.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.channel
                  ? MAdChannel.getKey(info.channel)
                  : "Select type of channel"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Interact"
            name="interact"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.interact)
                    return Promise.reject("Please input ad interactivity!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MAdInteract.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      interact: MAdInteract.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.interact
                  ? MAdInteract.getKey(info.interact)
                  : "Select type of interactivity"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item label="Text" name="text">
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item label="Banner" name="banner">
            <Upload
              listType="picture-card"
              fileList={info.banner}
              onPreview={onPreview}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
              onChange={({ fileList }) =>
                setInfo({ ...info, banner: fileList })
              }
            >
              {info.banner.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item label="Video" name="video">
            <Upload
              fileList={info.video}
              accept="video/*"
              onChange={({ fileList }) => setInfo({ ...info, video: fileList })}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
            >
              <Button icon={<CiVideoOn size={10} />}>Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Audio" name="audio">
            <Upload
              fileList={info.audio}
              accept="audio/*"
              onChange={({ fileList }) => setInfo({ ...info, audio: fileList })}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
            >
              <Button icon={<MdAudiotrack size={10} />}>Upload</Button>
            </Upload>
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={loading || creating}
              htmlType="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
