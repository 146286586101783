import { Navigate, Route, Routes } from "react-router-dom";
import CampaignEdit from "./Edit";
import CampaignList from "./List";
import CampaignNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<CampaignList />} />
      <Route path="new" element={<CampaignNew />} />
      <Route path=":id/edit" element={<CampaignEdit />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
