import { Dispatch, createContext, useContext, useReducer } from "react";
import Reducer from "./reducer";
import { TAction, TState } from "./types";

export const initalState: TState = {
  alert: null,
  user: null,
  isocode: null,
};

type TCtx = {
  state: TState;
  dispatch: Dispatch<TAction>;
};
const Ctx = createContext({} as TCtx);

export const useCTX = () => useContext(Ctx);

export function CTXProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const [state, dispatch] = useReducer(Reducer, initalState);
  return <Ctx.Provider value={{ state, dispatch }}>{children}</Ctx.Provider>;
}
