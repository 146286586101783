import { Button, Modal, Spin, Table, TableProps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { MdAdd, MdDeleteOutline, MdEdit, MdOutlineGroup } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IEmailGroup } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function EmailGroupList() {
  useTitle("Email Group List");

  const [emailGroups, setEmailGroups] = useState<IEmailGroup[]>([]);

  const [deleteEmailGroup, setDeleteEmailGroup] = useState<IEmailGroup | null>(
    null
  );

  const navigate = useNavigate();
  const { dispatch } = useCTX();

  const [listRequest, listLoading] = useRequest(true);
  const [deleteRequest, deleteLoading] = useRequest();

  const columns: TableProps<IEmailGroup>["columns"] = useMemo(
    () => [
      {
        key: "name",
        title: "Name",
        dataIndex: "name",
      },
      {
        key: "effective_date",
        title: "Effective Date",
        dataIndex: "effective_date",
      },
      {
        key: "contact_emails",
        title: "Contact Emails",
        render: (_, emailgroup) => (
          <span>{emailgroup.contact_emails.length}</span>
        ),
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, emailgroup: any) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeleteEmailGroup(emailgroup)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() =>
                navigate(`/home/email-groups/${emailgroup.id}/edit`)
              }
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "mailgroups/api/emailgroups/webremove/",
      () => {
        setEmailGroups((old) =>
          old.filter((p) => p.id !== deleteEmailGroup?.id)
        );
        setDeleteEmailGroup(null);
        dispatch(
          setAlert({
            type: "success",
            message: "EMAIL GROUP",
            description: "Email Group deleted successfully!",
          })
        );
      },
      {
        token: localStorage.getItem("token"),
        emailGroupId: deleteEmailGroup?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "mailgroups/api/emailgroups/websearch/",
      (res) => {
        setEmailGroups(res.data.emailgroups);
      },
      {
        token: localStorage.getItem("token"),
        pageRequested: 1,
      }
    );
  }, [listRequest]);

  if (listLoading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteEmailGroup}
        title="Delete Email Group"
        onCancel={() => setDeleteEmailGroup(null)}
        okText="Delete"
        confirmLoading={deleteLoading}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete email group '
          {deleteEmailGroup?.name}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <MdOutlineGroup className="mr-2" /> Email Groups
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/home/email-groups/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={emailGroups} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
