import { Navigate, Route, Routes } from "react-router-dom";
import BrandEdit from "./Edit";
import BrandList from "./List";
import BrandNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<BrandList />} />
      <Route path="new" element={<BrandNew />} />
      <Route path=":id/edit" element={<BrandEdit />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
