import { Spin } from "antd";
import { Fragment, useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Alert from "../components/Alert";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useRequest } from "../hooks/useRequest";
import { useCTX } from "../state";
import { setIsocode, setUser } from "../state/actionCreators";

export default function Layout() {
  const { dispatch } = useCTX();
  const { pathname } = useLocation();

  const [isoRequest, isoLoading] = useRequest(true);
  const [authRequest, authLoading] = useRequest();

  const hide = useMemo(() => pathname.includes("home"), [pathname]);

  useEffect(() => {
    isoRequest("get", "isocodes/api/webcode/", (res) => {
      dispatch(setIsocode(res.data.isocode));

      const token = localStorage.getItem("token");
      if (token) {
        authRequest(
          "post",
          "users/api/weblogin/",
          (res) => {
            dispatch(setUser(res.data.user));
          },
          { token }
        );
      }
    });
  }, [dispatch, isoRequest, authRequest]);

  if (isoLoading || authLoading) {
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <Fragment>
      <Alert />
      {!hide && <Navbar />}
      <div className="md:w-3/4 w-[90%] mx-auto min-h-screen">
        <Outlet />
      </div>
      <Footer />
    </Fragment>
  );
}
