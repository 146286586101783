import { Button, Modal, Spin, Table, TableProps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { MdAdd, MdDeleteOutline, MdEdit } from "react-icons/md";
import { SiBrandfolder } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IBrand } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function BrandList() {
  useTitle("Brand List");

  const [brands, setBrands] = useState<IBrand[]>([]);

  const [deleteBrand, setDeleteBrand] = useState<IBrand | null>(null);

  const navigate = useNavigate();
  const { dispatch } = useCTX();

  const [listRequest, listLoading] = useRequest(true);
  const [deleteRequest, deleteLoading] = useRequest();

  const columns: TableProps<IBrand>["columns"] = useMemo(
    () => [
      {
        key: "brand_name",
        title: "Name",
        dataIndex: "brand_name",
      },
      {
        key: "brand_nick",
        title: "Nickname",
        dataIndex: "brand_nick",
      },
      {
        key: "entity",
        title: "Entity",
        render: (_, brand) => <span>{brand.entity?.name}</span>,
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, brand) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeleteBrand(brand)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() => navigate(`/home/brands/${brand.id}/edit`)}
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "campaigns/api/brands/webremove/",
      () => {
        setBrands((old) => old.filter((b) => b.id !== deleteBrand?.id));
        setDeleteBrand(null);
        dispatch(
          setAlert({
            type: "success",
            message: "BRAND",
            description: "Brand deleted successfully!",
          })
        );
      },
      {
        token: localStorage.getItem("token"),
        brandId: deleteBrand?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "campaigns/api/brands/websearch/",
      (res) => {
        setBrands(res.data.brands);
      },
      {
        token: localStorage.getItem("token"),
        pageRequested: 1,
      }
    );
  }, [listRequest]);

  if (listLoading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteBrand}
        title="Delete Brand"
        onCancel={() => setDeleteBrand(null)}
        okText="Delete"
        confirmLoading={deleteLoading}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete brand '{deleteBrand?.brand_name}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <SiBrandfolder className="mr-2" /> Brands
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/home/brands/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={brands} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
