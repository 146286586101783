import { Button, DatePicker, Form, Input, Spin, Tag, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { MdEdit, MdSave } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IContactEmail, IEmailGroup, MEmailType } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  contactEmails: IContactEmail[];
  contactEmailChanges: { op: "A" | "R"; id: number }[];
};

export default function EmailGroupEdit() {
  useTitle("Edit Email Group");

  const [emailgroup, setEmailGroup] = useState<IEmailGroup>();
  const [query, setQuery] = useState("");
  const [contactEmails, setContactEmails] = useState<IContactEmail[]>([]);
  const [info, setInfo] = useState<TInfo>({
    contactEmails: [],
    contactEmailChanges: [],
  });

  const params = useParams();
  const navigate = useNavigate();

  const timeoutRef = useRef<NodeJS.Timeout>();

  const { dispatch } = useCTX();
  const [request, loading] = useRequest();
  const [detailRequest, detailLoading] = useRequest(true);
  const [contactEmailRequest, contactEmailLoading] = useRequest();

  const onSave = (values: any) => {
    const _info = {
      ...values,
      effective_date: values.effective_date.format("YYYY-MM-DD"),
      contactEmailChanges: JSON.stringify(info.contactEmailChanges),
      token: localStorage.getItem("token"),
      emailGroupId: emailgroup?.id,
    };

    request(
      "post",
      "mailgroups/api/emailgroups/webaddupdate/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "EMAIL GROUP",
            description: "Email Group updated successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    detailRequest(
      "post",
      "mailgroups/api/emailgroups/webdetail/",
      (res) => {
        const e = res.data.emailgroup;
        setEmailGroup(e);
        setInfo((old) => ({
          ...old,
          contactEmails: e.contact_emails,
        }));
      },
      {
        token: localStorage.getItem("token"),
        emailgroup_id: params.id,
      }
    );
  }, [params.id, detailRequest]);

  useEffect(() => {
    if (!detailLoading && !emailgroup) {
      navigate(-1);
    }
  }, [detailLoading, emailgroup, navigate]);

  useEffect(() => {
    if (query.trim().length > 1) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        contactEmailRequest(
          "post",
          "mailgroups/api/contactemail/websearch/",
          (res) => {
            const emails = res.data.contactemails;
            setContactEmails(emails);
          },
          {
            token: localStorage.getItem("token"),
            search_string: query,
            pageRequested: 1,
          }
        );
      }, 2000);
    } else {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setContactEmails([]);
    }
  }, [query, contactEmailRequest]);

  if (detailLoading) {
    <div className="w-[100vw] h-[100vh] flex items-center justify-center">
      <Spin />
    </div>;
  }

  if (!emailgroup) {
    return null;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdEdit className="mr-2" /> Contact
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{
            eg_name: emailgroup.name,
            eg_descr: emailgroup.description,
            effective_date: dayjs(emailgroup.effective_date),
          }}
          onFinish={onSave}
        >
          <Form.Item
            label="Name"
            name="eg_name"
            rules={[
              { required: true, message: "Please input email group name!" },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Description"
            name="eg_descr"
            rules={[
              {
                required: true,
                message: "Please input email group description!",
              },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item
            label="Effective Date"
            name="effective_date"
            rules={[
              {
                required: true,
                message: "Please input email group effective date!",
              },
            ]}
          >
            <DatePicker
              disabledDate={(current) => current && current < dayjs()}
            />
          </Form.Item>
          <Form.Item
            label="Contact Emails"
            name="contact_emails"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.contactEmails.length === 0) {
                    return Promise.reject(
                      "Please add atleast one contact email!"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <div className="relative">
              <Input.Search
                className="w-full"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                inputMode="email"
                loading={contactEmailLoading}
              />
              {contactEmailLoading || contactEmails.length > 0 ? (
                <div className="bg-gray-50 max-h-96 w-full absolute z-50">
                  {contactEmailLoading ? (
                    <div className="flex justify-center py-4">
                      <Spin size="small" />
                    </div>
                  ) : (
                    contactEmails.map((ce) => (
                      <div
                        className="flex items-center p-3 cursor-pointer hover:bg-white"
                        onClick={() => {
                          if (
                            info.contactEmails.findIndex(
                              (_ce) => _ce.id === ce.id
                            ) === -1
                          ) {
                            setInfo({
                              ...info,
                              contactEmails: [...info.contactEmails, ce],
                              contactEmailChanges: [
                                ...info.contactEmailChanges,
                                { op: "A", id: ce.id },
                              ],
                            });
                            setQuery("");
                          }
                        }}
                      >
                        <Tag>{MEmailType.getKey(ce.email_type)}</Tag>
                        <span className="ml-1 text-xs">{ce.email}</span>
                      </div>
                    ))
                  )}
                </div>
              ) : null}
            </div>
            <div className="mt-2 flex flex-col">
              {info.contactEmails.map((ce) => (
                <div className="flex items-center m-2">
                  <Tag>{MEmailType.getKey(ce.email_type)}</Tag>
                  <span className="ml-1 mr-8 text-xs">{ce.email}</span>
                  <Button
                    size="small"
                    icon={<RxCross2 />}
                    onClick={() => {
                      const _info = {
                        ...info,
                        contactEmails: info.contactEmails.filter(
                          (e) => e.id !== ce.id
                        ),
                      };

                      const ceidx = _info.contactEmailChanges.findIndex(
                        (c) => c.id === ce.id && c.op === "A"
                      );
                      if (ceidx !== -1) {
                        _info.contactEmailChanges.splice(ceidx, 1);
                      } else {
                        _info.contactEmailChanges.push({ op: "R", id: ce.id });
                      }

                      setInfo(_info);
                    }}
                  />
                </div>
              ))}
            </div>
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
