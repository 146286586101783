import axios from "axios";
import { getServerDomain } from "./utils";

export const caxios = axios.create({
  baseURL: getServerDomain(),
  withCredentials: true,
});

export const colors = {
  yellow: "#e9c749",
  blue: "#5e9be1",
};
