import { Navigate, Route, Routes } from "react-router-dom";
import ProductEdit from "./Edit";
import ProductList from "./List";
import ProductNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<ProductList />} />
      <Route path="new" element={<ProductNew />} />
      <Route path=":id/edit" element={<ProductEdit />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
