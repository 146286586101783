import { Avatar, Button, Dropdown, Image, Menu, MenuProps, Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBuysellads } from "react-icons/fa6";
import { AiOutlineHome, AiOutlineUserAdd } from "react-icons/ai";
import { CgProductHunt } from "react-icons/cg";
import { FaArrowRight, FaFacebook } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi";
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdCampaign,
  MdLockOutline,
  MdOutlineContactPhone,
  MdOutlineGroup,
} from "react-icons/md";
import { PiUsersThreeLight } from "react-icons/pi";
import { SiBrandfolder } from "react-icons/si";
import { useLocation, useNavigate } from "react-router-dom";
import { useRequest } from "../hooks/useRequest";
import { useCTX } from "../state";
import { setUser } from "../state/actionCreators";

const menu = new Map<string, string>([
  ["", "home"],
  ["about", "about"],
]);

function Navbar() {
  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const [request, loggingOut] = useRequest();

  const pathname = location.pathname.split("/").slice(-1)[0];
  const [current, setCurrent] = useState(
    menu.has(pathname) ? menu.get(pathname)! : ""
  );
  const [lngs, setLngs] = useState(["en", "es", "pt"]);
  const [menuOpen, setMenuOpen] = useState(false);

  const logout = useCallback(() => {
    request("post", "users/api/weblogout/", () => {
      dispatch(setUser(null));
      localStorage.removeItem("token");
      setCurrent("home");
    });
  }, [dispatch, request]);

  const lngMenu = useMemo(() => {
    const menu = lngs.map((l) => ({
      label: l.toUpperCase(),
      key: l,
      icon: (
        <Image
          src={`/images/flags/${l}.svg`}
          alt="en"
          width={20}
          height={20}
          className="pr-1 pt-1"
          preview={false}
        />
      ),
    })) as any;
    menu[0].children = menu.slice(1);
    return menu[0];
  }, [lngs]);

  const sideMenu: MenuProps["items"] = useMemo(
    () => [
      {
        key: "ads",
        label: "Ads",
        icon: <FaBuysellads />,
        onClick: () => navigate("/home/ads"),
      },
      {
        key: "brands",
        label: "Brands",
        icon: <SiBrandfolder />,
        onClick: () => navigate("/home/brands"),
      },
      {
        key: "products",
        label: "Products",
        icon: <CgProductHunt />,
        onClick: () => navigate("/home/products"),
      },
      {
        key: "campaigns",
        label: "Campaigns",
        icon: <MdCampaign />,
        onClick: () => navigate("/home/campaigns"),
      },
      {
        key: "contacts",
        label: "Contacts",
        icon: <MdOutlineContactPhone />,
        onClick: () => navigate("/home/contacts"),
      },
      {
        key: "email-groups",
        label: "Email Groups",
        icon: <MdOutlineGroup />,
        onClick: () => navigate("/home/email-groups"),
      },
      {
        key: "social-networks",
        label: "Social Networks",
        icon: <FaFacebook />,
        onClick: () => navigate("/home/social-networks"),
      },
      {
        key: "reset-password",
        label: "Reset Password",
        icon: <MdLockOutline />,
        onClick: () => navigate("/home/reset-password"),
      },
      {
        key: "logout",
        label: "Logout",
        icon: <FaArrowRight />,
        onClick: logout,
      },
    ],
    [logout, navigate]
  );

  const mainMenu = useMemo(() => {
    const _ = [
      lngMenu,
      {
        label: "Home",
        key: "home",
        icon: <AiOutlineHome />,
      },
      {
        label: "About",
        key: "about",
        icon: <PiUsersThreeLight />,
      },
    ];

    return _;
  }, [lngMenu]);

  useEffect(() => {
    setCurrent(menu.has(pathname) ? menu.get(pathname)! : "");
  }, [pathname]);

  return (
    <div className="flex md:w-3/4 w-[90%] py-8 justify-between mx-auto">
      <div className="w-32">
        <Image
          src={`/logo.jpeg`}
          preview={false}
          className="pt-5 pr-10 cursor-pointer"
          onClick={() => {
            setCurrent("home");
            navigate("/");
          }}
        />
      </div>
      <div className="w-3/4 flex flex-row-reverse md:flex-row justify-start md:justify-end items-center">
        <Menu
          className={`w-1/6 md:w-3/6`}
          mode="horizontal"
          selectedKeys={[current]}
          onClick={(i) => {
            const isLng = ["en", "es", "pt"].includes(i.key);
            if (isLng) {
              const _lngs = lngs.filter((l) => l !== i.key);
              setLngs([i.key, ..._lngs]);
              i18n.changeLanguage(i.key);
            } else {
              setCurrent(i.key);
              navigate(i.key === "home" ? "" : i.key);
            }
          }}
          items={mainMenu}
        />
        {user ? (
          loggingOut ? (
            <Spin size="small" className="ml-8" />
          ) : (
            <Dropdown
              menu={{ items: sideMenu }}
              trigger={["click"]}
              onOpenChange={(o) => setMenuOpen(o)}
            >
              <div className="ml-4 flex items-center cursor-pointer">
                <Avatar
                  className="w-10 h-10 hover:opacity-90 mr-2"
                  src={user?.avatar || "/images/no-avatar.jpeg"}
                />
                <span style={{ fontSize: "0.7rem" }}>{user?.display_name}</span>
                {menuOpen ? (
                  <MdArrowDropUp className="ml-2" />
                ) : (
                  <MdArrowDropDown className="ml-2" />
                )}
              </div>
            </Dropdown>
          )
        ) : (
          <div className="ml-0 md:ml-10 flex flex-nowrap">
            <Button
              onClick={() => {
                navigate("/auth/register");
                setCurrent("");
              }}
              type="primary"
              icon={<AiOutlineUserAdd size={10} />}
              style={{ marginRight: 4 }}
            >
              <span className="text-xs">Register</span>
            </Button>
            <Button
              icon={<FiLogIn size={10} />}
              onClick={() => {
                navigate("/auth/login");
                setCurrent("");
              }}
            >
              <span className="text-xs">Login</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
