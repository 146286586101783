import { Navigate, Route, Routes } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import SocialNetworks from "./SocialNetworks";
import AdLayout from "./ads/Layout";
import BrandLayout from "./brands/Layout";
import CampaignLayout from "./campaigns/Layout";
import ContactLayout from "./contacts/Layout";
import EmailGroupLayout from "./emailgroups/Layout";
import ProductLayout from "./products/Layout";

export default function Layout() {
  return (
    <Routes>
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="social-networks" element={<SocialNetworks />} />
      <Route path="contacts/*" element={<ContactLayout />} />
      <Route path="email-groups/*" element={<EmailGroupLayout />} />
      <Route path="ads/*" element={<AdLayout />} />
      <Route path="brands/*" element={<BrandLayout />} />
      <Route path="products/*" element={<ProductLayout />} />
      <Route path="campaigns/*" element={<CampaignLayout />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
