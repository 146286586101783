import { IIsocode, IUser } from "../lib/model";
import { AT } from "./actionTypes";
import { TAction, TAlert } from "./types";

export const setAlert = (alert: TAlert | null): TAction => {
  return {
    type: AT.SET_ALERT,
    payload: alert,
  };
};

export const setUser = (user: IUser | null): TAction => {
  return {
    type: AT.SET_USER,
    payload: user,
  };
};

export const setIsocode = (isocode: IIsocode | null): TAction => {
  return {
    type: AT.SET_ISOCODE,
    payload: isocode,
  };
};
