import { ConfigProvider } from "antd";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./i18n/config";
import { colors } from "./lib/constants";
import About from "./pages/About";
import AuthLayout from "./pages/auth/Layout";
import Home from "./pages/Home";
import HomeLayout from "./pages/home/Layout";
import MainLayout from "./pages/Layout";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.blue,
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="auth/*" element={<AuthLayout />} />
            <Route path="home/*" element={<HomeLayout />} />
          </Route>
          <Route path="*" element={<Navigate to={"/"} replace />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
