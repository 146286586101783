import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { MdEdit, MdSave } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IBrand, IOffering, IProduct, ISubcategory } from "../../../lib/model";
import { getBase64 } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  photo_main: UploadFile[];
  brand: IBrand | null;
  subcategory: ISubcategory | null;
  offering: IOffering | null;
};

export default function ProductEdit() {
  useTitle("Edit Product");

  const [product, setProduct] = useState<IProduct>();
  const [info, setInfo] = useState<TInfo>({
    photo_main: [],
    brand: null,
    subcategory: null,
    offering: null,
  });
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [subcategories, setSubcategories] = useState<ISubcategory[]>([]);
  const [offerings, setOfferings] = useState<IOffering[]>([]);

  const params = useParams();
  const navigate = useNavigate();

  const {
    dispatch,
    state: { user },
  } = useCTX();
  const { onPreview } = useImagePreview();
  const [updateRequest, updating] = useRequest();
  const [detailRequest, loading] = useRequest(true);
  const [listBrandRequest, listBrandLoading] = useRequest(true);
  const [listSubcategoryRequest, listSubcategoryLoading] = useRequest(true);
  const [listOfferingRequest, listOfferingLoading] = useRequest(true);

  const onSave = (values: any) => {
    const _info = new FormData();
    _info.append("token", localStorage.getItem("token")!);
    _info.append("productId", `${params.id}`);
    _info.append("product_name", values.product_name);
    _info.append("shortDescr", values.shortDescr);
    _info.append("longDescr", values.longDescr);
    _info.append("external_ref", values.external_ref);
    _info.append("brandId", `${info.brand?.id}`);
    _info.append("subcategoryId", `${info.subcategory?.id}`);

    if (info.photo_main[0].thumbUrl?.includes("data:")) {
      _info.append("photo_main", info.photo_main[0].originFileObj!);
    }

    if (info.offering) {
      _info.append("offeringId", `${info.offering?.id}`);
    }

    updateRequest(
      "post",
      "campaigns/api/products/webaddupdate/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "PRODUCT",
            description: "Product updated successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    detailRequest(
      "post",
      "campaigns/api/products/webdetail/",
      (res) => {
        const p = res.data.product;
        setProduct(p);
        setInfo({
          brand: p.brand,
          offering: p.offering,
          subcategory: p.subcategory,
          photo_main: p.photo_main
            ? [
                {
                  name: p.photo_main.split("/").slice(-1)[0],
                  uid: p.photo_main,
                  url: p.photo_main,
                  thumbUrl: p.photo_main,
                },
              ]
            : [],
        });
      },
      {
        token: localStorage.getItem("token"),
        product_id: params.id,
      }
    );
  }, [params.id, detailRequest]);

  useEffect(() => {
    listBrandRequest(
      "post",
      "campaigns/api/brands/websearch/",
      (res) => {
        setBrands(res.data.brands);
      },
      {
        token: localStorage.getItem("token"),
        pageRequested: 1,
      }
    );
  }, [listBrandRequest]);

  useEffect(() => {
    listSubcategoryRequest(
      "post",
      "others/api/subcategoriesList/",
      (res) => {
        setSubcategories(res.data.subcategories);
      },
      {
        email: user?.email,
        pageRequested: 1,
      }
    );
  }, [user?.email, listSubcategoryRequest]);

  useEffect(() => {
    listOfferingRequest(
      "post",
      "others/api/offering/weblist/",
      (res) => {
        setOfferings(res.data.offerings);
      },
      {
        token: localStorage.getItem("token"),
      }
    );
  }, [listOfferingRequest]);

  useEffect(() => {
    if (
      !loading &&
      !listBrandLoading &&
      !listSubcategoryLoading &&
      !listOfferingLoading &&
      !product
    ) {
      navigate(-1);
    }
  }, [
    loading,
    listBrandLoading,
    listSubcategoryLoading,
    listOfferingLoading,
    product,
    navigate,
  ]);

  if (
    loading ||
    listBrandLoading ||
    listSubcategoryLoading ||
    listOfferingLoading
  ) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!product) {
    return null;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdEdit className="mr-2" /> Product
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{
            product_name: product.product_name,
            shortDescr: product.shortDescr,
            longDescr: product.longDescr,
            external_ref: product.external_ref,
          }}
          onFinish={onSave}
        >
          <Form.Item label="Photo" name="photo_main">
            <Upload
              listType="picture-card"
              fileList={info.photo_main}
              onPreview={onPreview}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
              onChange={({ fileList }) =>
                setInfo({ ...info, photo_main: fileList })
              }
            >
              {info.photo_main.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Name"
            name="product_name"
            rules={[{ required: true, message: "Please input product name!" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Subcategory"
            name="subcategory"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.subcategory) {
                    return Promise.reject("Please select a subcategory!");
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: subcategories.map((s) => ({
                  key: s.subcategory_name,
                  label: s.subcategory_name,
                  icon: (
                    <Avatar
                      src={s?.photo_main || "/images/covers/empty.png"}
                      className="w-10 h-10"
                    />
                  ),
                  onClick: () => setInfo({ ...info, subcategory: s }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.subcategory ? (
                  <div className="flex items-center">
                    <Avatar
                      src={
                        info.subcategory.photo_main ||
                        "/images/covers/empty.png"
                      }
                      className="w-8 h-8 mr-2"
                    />
                    <span>{info.subcategory.subcategory_name}</span>
                  </div>
                ) : (
                  "Select the Subcategory"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Brand"
            name="brand"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.brand) {
                    return Promise.reject("Please select a brand!");
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: brands.map((b) => ({
                  key: b.id,
                  label: b.brand_name,
                  icon: (
                    <Avatar
                      src={b?.photo_main || "/images/covers/empty.png"}
                      className="w-10 h-10"
                    />
                  ),
                  onClick: () => setInfo({ ...info, brand: b }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.brand ? (
                  <div className="flex items-center">
                    <Avatar
                      src={info.brand?.photo_main || "/images/covers/empty.png"}
                      className="w-8 h-8 mr-2"
                    />
                    <span>{info.brand.brand_name}</span>
                  </div>
                ) : (
                  "Select the Brand"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="External Reference"
            name="external_ref"
            rules={[
              { required: true, message: "Please input external reference!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Short Description" name="shortDescr">
            <Input />
          </Form.Item>
          <Form.Item label="Long Description" name="longDescr">
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item label="Offering" name="offering">
            <Dropdown
              trigger={["click"]}
              menu={{
                items: offerings.map((o) => ({
                  key: o.id,
                  label: o.offer_name,
                  icon: (
                    <Avatar
                      src={o.offer_photo || "/images/covers/empty.png"}
                      className="w-10 h-10"
                    />
                  ),
                  onClick: () => setInfo({ ...info, offering: o }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.offering ? (
                  <div className="flex items-center">
                    <Avatar
                      src={
                        info.offering?.offer_photo || "/images/covers/empty.png"
                      }
                      className="w-8 h-8 mr-2"
                    />
                    <span>{info.offering.offer_name}</span>
                  </div>
                ) : (
                  "Select the Offering"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
