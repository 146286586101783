import { Mapper } from "./utils";

export interface IUser {
  id: number;
  name: string;
  email: string;
  display_name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  last_name_mother: string;
  last_name_father: string;
  avatar: string;
  cob: string;
  dtob: string;
  gender: string;
  doc_type: string;
  doc_issuer: string;
  doc_photo: string;
  doc_id: string;
  tax_id: string;
  rows_per_page: number;
  prov_status: "A" | "R" | "N";
  agent_status: "A" | "N";
  agent_is_manager: boolean;
  support_status: "A" | "R" | "W" | "N";
}

export interface IIsocode {
  country_name: string;
  country_continent: string;
  country_currency: string;
  currency_symbol: string;
  distance_unit: string;
  calling_code: number;
}

export interface IOffering {
  id: number;
  offer_name: string;
  offer_descr: string;
  offer_photo: string;
}

export interface ISubcategory {
  id: number;
  subcategory_name: string;
  category_name: string;
  short_descr: string;
  photo_main: string;
}

export interface IAccount {
  id: number;
  type: string;
  email: string;
  created: string;
}

export const MEmailType = new Mapper(
  ["Business", "Personal", "Work", "Other"],
  ["1", "2", "3", "4"]
);

export const MCampaignTarget = new Mapper(
  [
    "Demographic",
    "Psycographic",
    "Behavioral",
    "Geographic",
    "Temporary",
    "Custom",
    "Not Applicable",
    "Other",
  ],
  ["1", "2", "3", "4", "5", "6", "7", "99"]
);

export const MAdChannel = new Mapper(
  ["E-Mail", "SMS", "Web App", "Mobile App", "E-Board", "Other"],
  ["1", "2", "3", "4", "5", "6"]
);

export const MAdInteract = new Mapper(
  [
    "Survey",
    "QR Code",
    "Cut-Through",
    "Email Offer",
    "Influencer",
    "Other",
    "None",
  ],
  ["1", "2", "3", "4", "5", "6", "99"]
);

export const MGender = new Mapper(["Female", "Male"], ["F", "M"]);

export interface IContactEmail {
  id: number;
  email: string;
  email_type: string;
}

export interface IContact {
  id: number;
  name: string;
  nick_name: string;
  prefix_name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  sufix_name: string;
  fonetic_name: string;
  dtob: string;
  cob: string;
  gender: string;
  job_title: string;
  job_dept: string;
  entity: {
    id: number;
    entity_name: string;
  } | null;
  emails: IContactEmail[];
}

export interface IEntity {
  id: number;
  name: string;
  fantasy_name: string;
  photo: string;
}

export interface IEmailGroup {
  id: number;
  name: string;
  description: string;
  effective_date: string;
  contact_emails: IContactEmail[];
}

export interface IBrand {
  id: number;
  brand_name: string;
  brand_nick: string;
  photo_main: string;
  entity: IEntity | null;
}

export interface IProduct {
  id: number;
  product_name: string;
  category_name: string;
  subcategory: ISubcategory;
  shortDescr: string;
  longDescr: string;
  brand: IBrand;
  external_ref: string;
  photo_main: string;
  offering: IOffering | null;
}

export interface ICampaign {
  id: number;
  campaign_name: string;
  start_dt: string;
  end_dt: string;
  target: number;
  brand: IBrand | null;
}

export interface IAd {
  id: number;
  ad_name: string;
  channel: number;
  interact: number;
  banner: string;
  audio: string | null;
  video: string | null;
  text: string | null;
  campaign: ICampaign;
  product: IProduct;
}
