import { Button, Modal, Spin, Table, TableProps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { CgProductHunt } from "react-icons/cg";
import { MdAdd, MdDeleteOutline, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IProduct } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function ProductList() {
  useTitle("Product List");

  const [products, setProducts] = useState<IProduct[]>([]);

  const [deleteProduct, setDeleteProduct] = useState<IProduct | null>(null);

  const navigate = useNavigate();
  const { dispatch } = useCTX();

  const [listRequest, listLoading] = useRequest(true);
  const [deleteRequest, deleteLoading] = useRequest();

  const columns: TableProps<IProduct>["columns"] = useMemo(
    () => [
      {
        key: "product_name",
        title: "Name",
        dataIndex: "product_name",
      },
      {
        key: "brand_name",
        title: "Brand",
        render: (_, p) => <span>{p.brand.brand_name}</span>,
      },
      {
        key: "subcategory_name",
        title: "Subcategory",
        render: (_, p) => <span>{p.subcategory.subcategory_name}</span>,
      },
      {
        key: "external_ref",
        title: "External Ref",
        dataIndex: "external_ref",
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, product) => (
          <div className="flex">
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeleteProduct(product)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() => navigate(`/home/products/${product.id}/edit`)}
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "campaigns/api/products/webremove/",
      () => {
        setProducts((old) => old.filter((p) => p.id !== deleteProduct?.id));
        setDeleteProduct(null);
        dispatch(
          setAlert({
            type: "success",
            message: "PRODUCT",
            description: "Product deleted successfully!",
          })
        );
      },
      {
        token: localStorage.getItem("token"),
        productId: deleteProduct?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "campaigns/api/products/websearch/",
      (res) => {
        setProducts(res.data.products);
      },
      {
        token: localStorage.getItem("token"),
        pageRequested: 1,
      }
    );
  }, [listRequest]);

  if (listLoading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteProduct}
        title="Delete Product"
        onCancel={() => setDeleteProduct(null)}
        okText="Delete"
        confirmLoading={deleteLoading}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete product '
          {deleteProduct?.product_name}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <CgProductHunt className="mr-2" /> Products
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/home/products/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={products} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
