import { GoogleLogin } from "@react-oauth/google";
import { Button, Form, Input, Typography } from "antd";
import { jwtDecode } from "jwt-decode";
import { useCallback } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { PublicRoute } from "../../components/Route";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { colors } from "../../lib/constants";
import { useCTX } from "../../state";
import { setAlert } from "../../state/actionCreators";

type TInfo = {
  email: string;
  password: string;
  confirmPassword: string;
  account_type: string;
};

export default function Register() {
  useTitle("Register");

  const { dispatch } = useCTX();
  const navigate = useNavigate();
  const [request, loading] = useRequest();

  const onRegister = useCallback(
    (values: TInfo) => {
      const { confirmPassword, ..._values } = values;
      request(
        "post",
        "users/api/webregister/",
        () => {
          dispatch(
            setAlert({
              type: "success",
              message: "REGISTER",
              description:
                "user registered successfully, an email has been sent for verification, check your email inbox!",
            })
          );
          navigate("/auth/login");
        },
        _values
      );
    },
    [dispatch, navigate, request]
  );

  return (
    <PublicRoute>
      <div className="w-full lg:w-1/2 mx-auto">
        <Typography.Title
          level={4}
          className="font-poppins flex items-center py-8"
        >
          <AiOutlineUserAdd className="mr-2" /> Register
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          onFinish={(values) =>
            onRegister({ ...values, account_type: "Ezmsip" })
          }
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please input a valid email!" },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                min: 8,
                message: "Password must be minimum 8 characters in length.",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please input your password again!",
              },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!value || getFieldValue("password") === value)
                    return Promise.resolve();
                  return Promise.reject(new Error("Passwords must match!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Typography.Text className="text-xs">
              Already a user?{" "}
              <Link to={"/auth/login"}>
                <span
                  style={{ color: colors.blue }}
                  className="underline font-bold"
                >
                  login
                </span>
              </Link>
            </Typography.Text>
            <Button
              type="primary"
              className="text-xs ml-4"
              icon={<AiOutlineUserAdd size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        <div>
          <GoogleLogin
            text="signup_with"
            onSuccess={(res) => {
              if (res.credential) {
                const credential = jwtDecode(res.credential) as any;
                onRegister({
                  email: credential.email,
                  password: "google-login",
                  confirmPassword: "google-login",
                  account_type: "Ezmsip_Google",
                });
              }
            }}
          />
        </div>
      </div>
    </PublicRoute>
  );
}
