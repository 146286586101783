import { Button, Modal, Spin, Table, TableProps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { MdAdd, MdCampaign, MdDeleteOutline, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { ICampaign } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function CampaignList() {
  useTitle("Campaign List");

  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);

  const [deleteCampaign, setDeleteCampaign] = useState<ICampaign | null>(null);

  const navigate = useNavigate();
  const { dispatch } = useCTX();

  const [listRequest, listLoading] = useRequest(true);
  const [deleteRequest, deleteLoading] = useRequest();

  const columns: TableProps<ICampaign>["columns"] = useMemo(
    () => [
      {
        key: "campaign_name",
        title: "Name",
        dataIndex: "campaign_name",
      },
      {
        key: "brand",
        title: "Brand",
        render: (_, campaign) => <span>{campaign.brand?.brand_name}</span>,
      },
      {
        key: "start_dt",
        title: "Start Date",
        dataIndex: "start_dt",
      },
      {
        key: "end_dt",
        title: "End Date",
        dataIndex: "end_dt",
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, campaign) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeleteCampaign(campaign)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() => navigate(`/home/campaigns/${campaign.id}/edit`)}
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "campaigns/api/campaigns/webremove/",
      () => {
        setCampaigns((old) => old.filter((b) => b.id !== deleteCampaign?.id));
        setDeleteCampaign(null);
        dispatch(
          setAlert({
            type: "success",
            message: "CAMPAIGN",
            description: "Campaign deleted successfully!",
          })
        );
      },
      {
        token: localStorage.getItem("token"),
        campaignId: deleteCampaign?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "campaigns/api/campaigns/websearch/",
      (res) => {
        setCampaigns(res.data.campaigns);
      },
      {
        token: localStorage.getItem("token"),
        pageRequested: 1,
      }
    );
  }, [listRequest]);

  if (listLoading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteCampaign}
        title="Delete Brand"
        onCancel={() => setDeleteCampaign(null)}
        okText="Delete"
        confirmLoading={deleteLoading}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete campaign '
          {deleteCampaign?.campaign_name}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <MdCampaign className="mr-2" /> Campaigns
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/home/campaigns/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={campaigns} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
